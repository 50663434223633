import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/services.index';
import { PromiseComponent } from '../promise/promise.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { WebsocketService } from 'src/app/services/websocket.service';
import { CarService } from '../car/car.service';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



@Component({
  selector: 'app-car-comparison',
  templateUrl: './car-comparison.component.html',
  styleUrls: ['./car-comparison.component.css'],
  providers: [CarService]
})
export class CarComparisonComponent {


  loader:boolean = true;
  loaderModal:boolean = false;
  quotes:any = [];
  data:any = [];
  paquetes:any = [];
  frecuencias:any = [];
  mensajes:any;
  mensajesClose:any;
  coverages:any = [];
  total:any = 0;
  IDCotizacion: any = '';
  formQuoter:FormGroup = Object.create(null);
  url:string = '';
  provider:string = '';
  categories:any = [];
  brands:any = [];
  subbrands:any = [];
  types:any = [];
  models:any = [];
  versions:any = [];
  reQuotes:any = [];
  reTotal: number = 0;

  constructor(
    public router: Router,
    public _settings: SettingsService,
    public _api: PromiseComponent,
    private fb: FormBuilder,
    private wsService: WebsocketService,
    public _services: CarService,

  ) {
    window.scrollTo(0, 0);
    this.data = this._settings['settings']['quote'];
    this.formQuoter = this.fb.group({
      ciudad: [this.data['ciudad'], Validators.required],
      clave: [this.data['clave'], Validators.required],
      clave_auto: ['', Validators.required],
      colonia: [this.data['colonia'], Validators.required],
      correo: [this.data['correo'], Validators.required],
      cp: [this.data['cp'], Validators.required],
      descripcion: [this.data['descripcion'], Validators.required],
      estado: [this.data['estado'], Validators.required],
      empresa: ['gpi', Validators.required],
      fecha_nacimiento: [this.data['fecha_nacimiento'], Validators.required],
      forma_de_pago: [this.data['forma_de_pago'], Validators.required],
      cotizacion_id: ['', Validators.required],
      nombre: [this.data['nombre'], Validators.required],
      marca: [this.data['marca'], Validators.required],
      modelo: [this.data['modelo'], Validators.required],
      origen: ['miurabox', Validators.required],
      paquete: [this.data['paquete'], Validators.required],
      poblacion: [this.data['poblacion'], Validators.required],
      telefono: [this.data['telefono'], Validators.required],
      sexo: [this.data['sexo'], Validators.required],
      submarca: [this.data['submarca'], Validators.required],
      check: [this.data['check'], Validators.required],
      tipo: ['', Validators.required]
    });
    this.paquetes = [
      {paquete: 'Amplia', id: '01'},
      {paquete: 'Limitada', id: '03'},
      {paquete: 'RC', id: '04'}
    ];
    this.formQuoter.controls['paquete'].setValue(this.data['paquete']);
    this.frecuencias = [
      {frecuencia: 'Anual', id: 'C'},
      {frecuencia: 'Semestral', id: 'S'},
      {frecuencia: 'Trimestral', id: 'T'},
      {frecuencia: 'Mensual', id: 'M'}
    ];
    this.formQuoter.controls['forma_de_pago'].setValue(this.data['forma_de_pago']);

    if(this._settings['settings']['results'].length > 0){
      this.quotes = this._settings['settings']['results'];      
      this.total = 6;
      this.loader = false;
      
    }else{
      this.quoter();
    }
    console.log(this.data);

  }

  quoter(){
    this.total=0;
    if(this.data){
      this.total = 0;
      this._services.getQuote(this.data).then((response:any) => {
        
        let array = [];
        this.IDCotizacion =  response['cotizacion_principal'];
        this._settings['settings']['id_cotizacion'] = this.IDCotizacion;
        this._settings.saveSettings();
        this.mensajes = <BehaviorSubject<any>>this.wsService.connect(environment.SOCKET_ENDPOINT_NOTIFICACIONES + this.IDCotizacion)
        this.mensajes
        .subscribe((msg:any) => {
          this.total = this.total + 1;
          let message = JSON.parse(JSON.parse(msg['data'])['message'])
          if(message['error'] || message['ERROR'] || message['errMsg']){
          }else{
            let payload:any = {}
            message['cliente'] = {nombre: this.data['nombre'] + ' ' + this.data['apellido_paterno'] + ' ' + this.data['apellido_materno'], correo: this.data['correo'], telefono: this.data['telefono']};
            payload[message['aseguradora']] = JSON.stringify(message);
            payload[message['aseguradora'] + '_id'] = message['raw_response']['id'];
            this._services.saveQuote(this.IDCotizacion, payload)
            if(message['aseguradora'] == 'ana' && this.formQuoter['value']['forma_de_pago'] != 'C'){
              message['recibos'] = message['recibos'].map((item:any) =>{
                item['primatotal'] = item['@primatotal'];
                return item;
              })
            }
            this.quotes.push(message);
          }
          console.log('Mensaje recibido en WS', message);
          if(this.quotes.length > 0){
            this.loader = false;
          }
          if(this.total == 6){
            this.mensajesClose = <BehaviorSubject<any>>this.wsService.close(environment.SOCKET_ENDPOINT_NOTIFICACIONES + this.IDCotizacion);
            this._settings['settings']['results'] = this.quotes;
            this._settings.saveSettings();
            this.loader = false;
            if(this.quotes.length > 0){
              setTimeout(() => {
                this.sendEmail('');
              }, 3000);
            }
          }
        })
  
      }).catch(error => {
        console.log(error);
        Swal.fire('Error', error['error']['message'], 'error');
        this.router.navigate(['/cotizador']);
      });
    }else{
      this.router.navigate(['/cotizador']);
    }
  }

  downloadPdf(){
    this.loader = true;
    const DATA:any = document.getElementById('htmlData');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(DATA, options).then((canvas:any) => {
      const img = canvas.toDataURL('image/PNG');
      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult:any) => {
      this.loader = false;
      docResult.output('dataurlnewwindow', {filename: 'seguro_axa.pdf'});
    });
  }

  sendEmail(provider:string){
    // this.loader = true;
    const DATA:any = document.getElementById('htmlData');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(DATA, options).then((canvas:any) => {
      const img = canvas.toDataURL('image/PNG');
      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 32;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      let pdf = img.replace('data:image/png;base64,', '');

      if(provider != ''){
        switch(provider){
          case 'ana':
            provider = 'ANA'
            break;
          case 'chubb':
            provider = 'CHUBB'
            break;
          case 'hdi':
            provider = 'HDI'
            break;
          case 'gnp':
            provider = 'GNP'
            break;
          case 'ps':
            provider = 'PRIMERO SEGUROS'
            break;
          default:
            break;
        }
      }
      let data = {
        subject: provider == '' ? 'Cotización Avantthya' : 'ReCotización ' + provider + ' Avantthya',
        base64: pdf,
        correo: this.data['correo'],
        id: this.IDCotizacion,
        nombre: this.data['nombre'],
        origen: "avantthya"
      }
      this._services.sendEmail(data).then((response:any) => {
        // this.loader = false;
        if(response['sent'] = 'sent'){
          Swal.fire('Listo','Cotización enviada por correo.', 'info');
        }else{
          Swal.fire('Error', 'Error al enviar la cotización por correo electrónico.', 'error');
        }
      }).catch(error => {
        console.log(error);
        Swal.fire('Error', 'Ocurrió un error', 'error');
        this.loader = false;
      });
      return doc;
    }).then((docResult:any) => {
      this.loader = false;
    });
  }

  onSubmit(){
    this._settings['settings']['quote'] = this.formQuoter['value'];
    this._settings.saveSettings();
    this.data['paquete'] = this.formQuoter['value']['paquete'];
    this.data['forma_de_pago'] = this.formQuoter['value']['forma_de_pago'];
    this.quotes = [];
    this.loader = true;
    this.quoter();
  }

  redirect(valor:string){
    this.mensajesClose = <BehaviorSubject<any>>this.wsService.close(environment.SOCKET_ENDPOINT_NOTIFICACIONES + this.IDCotizacion)
    this.router.navigate(['/' + valor]);
  }

  issue(item:any){
    // this.mensajesClose = <BehaviorSubject<any>>this.wsService.close(environment.SOCKET_ENDPOINT_NOTIFICACIONES + this.IDCotizacion)
    item.id_cotizacion = this.IDCotizacion;
    this._settings['settings']['selected_quote'] = item;
    this._settings.saveSettings();
    this.router.navigate(['/emision-auto']);

  }

  showCoverages(item:any){
    this.coverages = item;
  }

  reQuoter(item:any){
    this.reTotal = 0;
    this.provider = item['aseguradora'];
    console.log(this.provider);
    
    switch(item['aseguradora']){
      case 'ana':
        this.url = environment.URL_ANA;
        this.getTypesAna();
        this.getModelsAna();
        break;
      case 'chubb':
        this.url = environment.URL_CHUBB;
        this.getbrandsChubb();
        break;
      case 'gnp':
        this.url = environment.URL_GNP;
        this.getBrandsGnp();
        break;
      case 'hdi':
        this.url = environment.URL_HDI;
        this.getTypesHdi();
        break;
      case 'ps':
        this.url = environment.URL_PS;
        this.getBrandsPs();
        break;
      default:
        break;
    }
  }

  /*******************+********ANA******************************/
  getTypesAna(){
    this.brands = [];
    this.subbrands = [];
    this.versions = [];
    this.brands = [
      {text: 'Selecciona una marca', id: '0'}
    ];
    this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
    this.subbrands = [
      {text: 'Selecciona un modelo', id: '0'}
    ];
    this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
    this.versions = [
      {text: 'Selecciona una versión', id: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['id']);
    
    this.loaderModal = true;
    this._services.getTypesAna(this.url).then((response:any) => {
      this.categories = response['transacciones']['categoria'];
      this.categories = this.categories.map((item:any) =>{
        item['text'] = item['#text'];
        item['id'] = item['@id'];
        return item;
      })
      this.categories.unshift({text: 'Selecciona un tipo', id: 'O'});
      this.formQuoter.controls['tipo'].setValue(this.categories[0]['id']);
      this.loaderModal = false;
    }).catch(error => {
        console.log(error);
    });
  }

  getModelsAna(){
    this.models = [];
    this.versions = [];
    this.brands = [
      {text: 'Selecciona una marca', id: '0'}
    ];
    this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
    this.subbrands = [
      {text: 'Selecciona un modelo', id: '0'}
    ];
    this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
    this.versions = [
      {text: 'Selecciona una versión', id: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['id']);
    this.loaderModal = true;
    this._services.getModelsAna(this.url).then((response:any) => {
      let models:any = response['transacciones']['modelo'];       
      for(let item of models){
        this.models.push({text: item, id: item});
      }
      this.models.unshift({text: 'Selecciona un año', id: '0'});
      this.formQuoter.controls['modelo'].setValue(this.models[0]['id']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }

  getBrandsAna(){
    this.brands = [];
    this.subbrands = [];
    this.versions = [];
    this.brands = [
      {text: 'Selecciona una marca', id: '0'}
    ];
    this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
    this.subbrands = [
      {text: 'Selecciona un modelo', id: '0'}
    ];
    this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
    this.versions = [
      {text: 'Selecciona una versión', id: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['id']);
    if(this.formQuoter['value']['tipo'] != 0 && this.formQuoter['value']['modelo'] != 0){
      this.loaderModal = true;
      this._services.getBrandsAna(this.url, this.formQuoter['value']['tipo'], this.formQuoter['value']['modelo']).then((response:any) => {
        this.brands = response['transacciones']['marca'];       
        this.brands = this.brands.map((item:any) =>{
          item['text'] = item['#text'];
          item['id'] = item['@id'];
          return item;
        })
        this.brands.unshift({text: 'Selecciona una marca', id: 'O'});
        this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
        this.loaderModal = false;
      }).catch(error => {
        console.log(error);
      });
    }
  }

  getSubbrandsAna(){
    this.subbrands = [];
    this.versions = [];
    this.versions = [
      {text: 'Selecciona una versión', id: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['id']);
    this.loaderModal = true;
    this._services.getSubbrandsAna(
      this.url, 
      this.formQuoter['value']['tipo'], 
      this.formQuoter['value']['modelo'], 
      this.formQuoter['value']['marca']).then((response:any) => {
      this.subbrands = response['transacciones']['submarca'];
      if(this.subbrands['#text']){
        let item = [];
        item.push(this.subbrands);
        this.subbrands = item.map((item:any) =>{
          item['text'] = item['#text'];
          item['id'] = item['@clave'];
          return item;
        })
      }else{
        this.subbrands = this.subbrands.map((item:any) =>{
          item['text'] = item['#text'];
          item['id'] = item['@clave'];
          return item;
        })
      }  
      this.subbrands.unshift({text: 'Selecciona un modelo', id: '0'});
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  };

  getVersionsAna(){
    this.versions = [];
    this.loaderModal = true;
    this._services.getVersionsAna(this.url, this.formQuoter['value']['tipo'], this.formQuoter['value']['modelo'], this.formQuoter['value']['marca'], this.formQuoter['value']['submarca']).then((response:any) => {
       this.versions = response['transacciones']['vehiculo']; 
       if(this.versions['#text']){
        let item = [];
        item.push(this.versions);
        this.versions = item.map((item:any) =>{
          item['text'] = item['#text'];
          item['id'] = item['@clave'];
          return item;
        })
      }else{
        this.versions = this.versions.map((item:any) =>{
          item['text'] = item['#text'];
          item['id'] = item['@clave'];
          return item;
        })
      }      
      this.versions.unshift({text: 'Selecciona una versión', id: '0'});
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['id']);
      this.loaderModal = false;
    });
  }

  getClaveAna(){
    for(let item of this.versions){
      if(item['id'] == this.formQuoter['value']['clave_auto']){
        this.formQuoter.controls['descripcion'].setValue(item['text']);
      }
    }
  }

  /*******************+********CHUBB******************************/
  getbrandsChubb(){
    this.subbrands = [
      {descripcion: 'Selecciona una submarca', id: '0'}
    ];
    this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['descripcion']);
    this.models = [
      {descripcion: 'Selecciona un año', id: '0'}
    ];
    this.formQuoter.controls['modelo'].setValue(this.models[0]['descripcion']);
    this.categories = [
      {descripcion: 'Selecciona un modelo', id: '0'}
    ];
    this.formQuoter.controls['tipo'].setValue(this.categories[0]['descripcion']);
    this.versions = [
      {descripcion: 'Selecciona una versión', cveveh: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['cveveh']);
    this.loaderModal = true;
    this._services.getBrandsChubb(this.url).then(response => {
      this.brands = response;
      this.brands.unshift({descripcion: 'Selecciona una marca', id: 'O'});
      this.formQuoter.controls['marca'].setValue(this.brands[0]['descripcion']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }

  getSubbrandsChubb(){
    this.subbrands = [];
    this.models = [];
    this.versions = [];
    this.models = [
      {descripcion: 'Selecciona un año', id: '0'}
    ];
    this.formQuoter.controls['modelo'].setValue(this.models[0]['descripcion']);
    this.categories = [
      {descripcion: 'Selecciona un modelo', id: '0'}
    ];
    this.formQuoter.controls['tipo'].setValue(this.categories[0]['descripcion']);
    this.versions = [
      {descripcion: 'Selecciona una versión', cveveh: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['cveveh']);
    this.loaderModal = true;
    this._services.getSubbrandsChubb(this.url, this.formQuoter['value']['marca']).then(response => {
      this.subbrands = response;
      this.subbrands.unshift({descripcion: 'Selecciona una submarca', id: '0'});
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['descripcion']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }
  
  getModelsChubb(){
    this.models = [];
    this.categories = [];
    this.versions = [];
    this.categories = [
      {descripcion: 'Selecciona un modelo', id: '0'}
    ];
    this.formQuoter.controls['tipo'].setValue(this.categories[0]['descripcion']);
    this.versions = [
      {descripcion: 'Selecciona una versión', cveveh: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['cveveh']);
    this.loaderModal = true;
    this._services.getModelsChubb(this.url, this.formQuoter['value']['marca'], this.formQuoter['value']['submarca']).then(response => {
      let models:any = response;
      for(let item of models){
        this.models.push({descripcion: item, id: item});
      }
      this.models.unshift({descripcion: 'Selecciona un año', id: '0'});
      this.formQuoter.controls['modelo'].setValue(this.models[0]['descripcion']);
      this.loaderModal = false;
    });
  }

  getTypesChubb(){
    this.categories = [];
    this.versions = [];
    this.versions = [
      {descripcion: 'Selecciona una versión', cveveh: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['cveveh']);
    this.loaderModal = true;
    this._services.getTypesChubb(this.url, this.formQuoter['value']['marca'], this.formQuoter['value']['submarca'], this.formQuoter['value']['modelo']).then(response => {
      this.categories = response;
      this.categories.unshift({descripcion: 'Selecciona un tipo', id: '0'});
      this.formQuoter.controls['tipo'].setValue(this.categories[0]['descripcion']);
      this.loaderModal = false;
    });
  }
  
  getVersionsChubb(){
    this.versions = [];
    this.loaderModal = true;
    this._services.getVersionsChubb(this.url, this.formQuoter['value']['marca'], this.formQuoter['value']['submarca'], this.formQuoter['value']['modelo'], this.formQuoter['value']['tipo']).then(response => {
      this.versions = response;
      this.versions.unshift({descripcion: 'Selecciona una versión', cveveh: '0'});
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['cveveh']);
      this.loaderModal = false;
    });
  }

  getClaveChubb(){
    for(let item of this.versions){
      if(item['cveveh'] == this.formQuoter['value']['clave_auto']){
        this.formQuoter.controls['descripcion'].setValue(item['descripcion']);
      }
    }
  }

  /*******************+********GNP******************************/
  getBrandsGnp(){
    this.brands = [];
    this.subbrands = [];
    this.versions = [];
    this.subbrands = [
      {descr: 'Selecciona una submarca', id: '0'}
    ];
    this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
    this.models = [
      {descr: 'Selecciona un año', id: '0'}
    ];
    this.formQuoter.controls['modelo'].setValue(this.models[0]['id']);
    this.versions = [
      {descr: 'Selecciona una versión', clave: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
    this.loaderModal = true;
    this._services.getBrandsGnp(this.url).then((response:any) => {
      this.brands = response;
      this.brands.unshift({descr: 'Selecciona una marca', id: 'O'});
      this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }

  getSubbrandsGnp(){
    this.subbrands = [];
    this.models = [];
    this.versions = [];
    this.models = [
      {descr: 'Selecciona un año', id: '0'}
    ];
    this.formQuoter.controls['modelo'].setValue(this.models[0]['id']);
    this.versions = [
      {descr: 'Selecciona una versión', clave: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
    this.loaderModal = true;
    this._services.getSubbrandsGnp(this.url, this.formQuoter['value']['marca']).then((response:any) => {
      this.subbrands = response;
      this.subbrands.unshift({descr: 'Selecciona un modelo', id: '0'});
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  };

  getModelsGnp(){
    this.models = [];
    this.versions = [];
    this.versions = [
      {descr: 'Selecciona una versión', clave: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
    this.loaderModal = true;
    this._services.getModelsGnp(this.url, this.formQuoter['value']['marca'], this.formQuoter['value']['submarca']).then((response:any) => {
      let models:any = response;       
      for(let item of models){
        this.models.push({descr: item, id: item});
      }
      this.models.unshift({descr: 'Selecciona un año', id: '0'});
      this.formQuoter.controls['modelo'].setValue(this.models[0]['id']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }

  getVersionsGnp(){
    this.versions = [];
    this.loaderModal = true;
    this._services.getVersionsGnp(this.url, this.formQuoter['value']['marca'], this.formQuoter['value']['submarca'], this.formQuoter['value']['modelo']).then((response:any) => {
       this.versions = response;    
      this.versions.unshift({descr: 'Selecciona una versión', clave: '0'});
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }

  getClaveGnp(){
    for(let item of this.versions){
      if(item['clave'] == this.formQuoter['value']['clave_auto']){
        this.formQuoter.controls['descripcion'].setValue(item['descr']);
      }
    }
  }

    /*******************+********HDI******************************/
    
    getTypesHdi(){
      this.types = [];
      this.brands = [];
      this.subbrands = [];
      this.models = [];
      this.versions = [];
      this.brands = [
        {descr: 'Selecciona una marca', id: '0'}
      ];
      this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
      this.subbrands = [
        {descr: 'Selecciona un modelo', id: '0'}
      ];
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
      this.models = [
        'Selecciona el año'
      ];
      this.formQuoter.controls['modelo'].setValue(this.models[0]);
      this.versions = [
        {version_descr: 'Selecciona una versión', idVehiculo: '0'}
      ];
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['idVehiculo']);
      this.loaderModal = true;
      this._services.getTypesHdi(this.url).then((response:any) => {
        this.types = response;
        this.types.unshift({descr: 'Selecciona un tipo', id: 'O'});
        this.formQuoter.controls['tipo'].setValue(this.types[0]['id']);
        this.loaderModal = false;
      }).catch(error => {
          console.log(error);
      });
    }

    getBrandsHdi(){
      this.brands = [];
      this.subbrands = [];
      this.models = [];
      this.versions = [];
      this.subbrands = [
        {descr: 'Selecciona un modelo', id: '0'}
      ];
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
      this.models = [
        'Selecciona el año'
      ];
      this.formQuoter.controls['modelo'].setValue(this.models[0]);
      this.versions = [
        {version_descr: 'Selecciona una versión', idVehiculo: '0'}
      ];
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['idVehiculo']);
      if(this.formQuoter['value']['tipo'] != 0 ){
        this.loaderModal = true;
        this._services.getBrandsHdi(
          this.url, 
          this.formQuoter['value']['tipo']).then((response:any) => {
          this.brands = response;       
          this.brands.unshift({descr: 'Selecciona una marca', id: '0'});
          this.formQuoter.controls['marca'].setValue(this.brands[0]['id']);
          this.loaderModal = false;
        }).catch(error => {
          console.log(error);
        });
      }
    }

    getSubbrandsHdi(){
      this.subbrands = [];
      this.models = [];
      this.versions = [];
      this.models = [
        'Selecciona el año'
      ];
      this.formQuoter.controls['modelo'].setValue(this.models[0]);
      this.versions = [
        {version_descr: 'Selecciona una versión', idVehiculo: '0'}
      ];
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['idVehiculo']);
      this.loaderModal = true;
      this._services.getSubbrandsHdi(
        this.url, 
        this.formQuoter['value']['tipo'],
        this.formQuoter['value']['marca']).then((response:any) => {
        let subbrands = response
        for(let item of subbrands){
          this.subbrands.push({descr: item, id: item});
        }
        this.subbrands.unshift({descr: 'Selecciona un modelo', id: '0'});        
        this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['id']);
        this.loaderModal = false;
      }).catch(error => {
        console.log(error);
      });
    }

    getModelsHdi(){
      this.models = [];
      this.versions = [];
      this.versions = [
        {version_descr: 'Selecciona una versión', idVehiculo: '0'}
      ];
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['idVehiculo']);
      this.loaderModal = true;
      this._services.getModelsHdi(
        this.url, 
        this.formQuoter['value']['tipo'],
        this.formQuoter['value']['marca'],
        this.formQuoter['value']['submarca']).then((response:any) => {
        this.models = response;    
        this.models.unshift('Selecciona un año');
        this.formQuoter.controls['modelo'].setValue(this.models[0]);
        this.loaderModal = false;
      }).catch(error => {
        console.log(error);
      });
    }
  

    getVersionsHdi(){
      this.versions = [];
      this.loaderModal = true;
      this._services.getVersionsHdi(
        this.url, 
        this.formQuoter['value']['tipo'], 
        this.formQuoter['value']['marca'], 
        this.formQuoter['value']['modelo'], 
        this.formQuoter['value']['submarca']).then((response:any) => {
        this.versions = response; 
        this.versions.unshift({version_descr: 'Selecciona una versión', idVehiculo: '0'});
        this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['idVehiculo']);
        this.loaderModal = false;
      });
    }
  
    getClaveHdi(){
      for(let item of this.versions){
        if(item['idVehiculo'] == this.formQuoter['value']['clave_auto']){
          this.formQuoter.controls['descripcion'].setValue(item['version_descr']);
        }
      }
    }
  

  /*******************+********PRIMERO******************************/
  getBrandsPs(){
    this.brands = [];
    this.subbrands = [];
    this.versions = [];
    this.subbrands = [
      {subMarca: 'Selecciona una submarca', idSubMarca: '0'}
    ];
    this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['idSubMarca']);
    this.versions = [
      {version: 'Selecciona una versión', clave: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
    this.loaderModal = true;
    this._services.getBrandsPs(this.url).then((response:any) => {
      this.brands = response;       
      this.brands.unshift({marca: 'Selecciona una marca', idMarca: 'O'});
      this.formQuoter.controls['marca'].setValue(this.brands[0]['idMarca']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  }

  getSubbrandsPs(){
    this.subbrands = [];
    this.versions = [];
    this.versions = [
      {version: 'Selecciona una versión', clave: '0'}
    ];
    this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
    this.loaderModal = true;
    this._services.getSubbrandsPs(this.url, this.formQuoter['value']['marca']).then((response:any) => {
      this.subbrands = response;
      this.subbrands.unshift({subMarca: 'Selecciona una submarca', idSubMarca: '0'});
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['idSubMarca']);
      this.loaderModal = false;
    }).catch(error => {
      console.log(error);
    });
  };

  getVersionsPs(){
    this.versions = [];
    this.loaderModal = true;
    this._services.getVersionsPs(this.url, this.formQuoter['value']['marca'], this.formQuoter['value']['submarca'], this.data['modelo']).then((response:any) => {
       this.versions = response;
      this.versions.unshift({version: 'Selecciona una versión', clave: '0'});
      this.formQuoter.controls['clave_auto'].setValue(this.versions[0]['clave']);
      this.loaderModal = false;
    });
  }

  getClavePs(){
    for(let item of this.versions){
      if(item['clave'] == this.formQuoter['value']['clave_auto']){
        this.formQuoter.controls['descripcion'].setValue(item['version']);
      }
    }
  }

  onSubmitProvider(){
    this.loaderModal = true;
    if(!this.IDCotizacion){
      this.IDCotizacion = this._settings['settings']['id_cotizacion']
    }
    this.formQuoter.controls['cotizacion_id'].setValue(this.IDCotizacion);
    this._services.getReQuote(this.url, this.provider, this.formQuoter['value']).then((response:any) => {
      this.reQuotes = [];
      this.reTotal = 0;
      this.IDCotizacion =  response['cotizacion_principal']
      this.mensajes = <BehaviorSubject<any>>this.wsService.connect(environment.SOCKET_ENDPOINT_NOTIFICACIONES + this.IDCotizacion)
      this.mensajes
      .subscribe((msg:any) => {
        this.reTotal = this.reTotal + 1;
        let message = JSON.parse(JSON.parse(msg['data'])['message'])
        if(message['error'] || message['ERROR'] || message['errMsg']){

        }else{
          console.log(message);
          
          if(message['aseguradora'] == 'ana' && this.formQuoter['value']['forma_de_pago'] != 'C'){
            message['recibos'] = message['recibos'].map((item:any) =>{
              item['primatotal'] = item['@primatotal'];
              return item;
            })
          }
          if(message['aseguradora'] == this.provider){
            for(let i in this.quotes){
              if(this.quotes[i]['aseguradora'] == message['aseguradora']){
                this.quotes[i] = message;
              }
            }
          }
          this.reQuotes.push(message);
        }
        this.mensajesClose = <BehaviorSubject<any>>this.wsService.close(environment.SOCKET_ENDPOINT_NOTIFICACIONES + this.IDCotizacion);
        this._settings['settings']['results'] = this.quotes;
        this._settings.saveSettings();
        this.loaderModal = false;
        setTimeout(() => {
          this.sendEmail(this.provider);
        }, 3000);
        // location.reload();
      })
    }).catch(error => {
      console.log(error);
      this.loaderModal = false;
    });

  }

  goBack(){
    this.router.navigate(['/cotizador-auto']);
  }

}
