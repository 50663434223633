<div class="container-main">
  <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <div class="container-orange">
            <p class="text-white-search">¿Buscas un Seguro?</p>
            <p class="text-white-do">¡Hazlo sin miedo!</p>
            <p class="text-purple">Precios justos <span class="point-orange">·</span> Atención de primera <span class="point-orange">·</span> Respaldo total</p>
            <p class="text-white-home">¿Qué quieres asegurar?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div  class="col-12 form-group">
          <div class="row container-image">
            <div class="col-12 col-sm-4 form-group">
              <div class="image-car" (click)="goToPage('cotizador-auto')"></div>
              <button class="button-purple" (click)="goToPage('cotizador-auto')">Mi Auto</button>
            </div>
            <div class="col-12 col-sm-4 form-group">
              <div class="image-house" data-bs-toggle="modal" data-bs-target="#exampleModal"></div>
              <button class="button-purple" data-bs-toggle="modal" data-bs-target="#exampleModal">Mi Casa o Depa</button>
            </div>
            <div class="col-12 col-sm-4 form-group">
              <div class="image-medical" data-bs-toggle="modal" data-bs-target="#exampleModal"></div>
              <button class="button-purple" data-bs-toggle="modal" data-bs-target="#exampleModal">Mi Salud</button>
              <p class="text-image"><span class="text-image-small">Gastos Médicos Mayores</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <div class="container-purple">
            <div  class="col-12 form-group">
              <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="container-transparent">
                      <p class="text-purple-left">Si necesitas una señal para asegurar tu auto ES ESTA</p>
                      <p class="text-orange-discount">Hasta 50% de descuento</p>
                      <p class="text-orange">Seguro de Auto</p>
                      <button class="button-orange" (click)="goToPage('cotizador-auto')">Solicitar cotización</button>
                    </div>
                    <img src="../../../assets/images/home/img_seguro_auto.png" class="image-carousel" alt="...">
                  </div>
                  <div class="carousel-item">
                    <div class="container-transparent">
                      <p class="text-purple-left">¡Seguro esto te conviene! Asegura tu auto</p>
                      <p class="text-orange-discount">Cómodas formas de pago</p>
                      <p class="text-orange">Hasta 50% de DESCUENTO</p>
                      <p class="space"></p>
                    </div>
                    <img src="../../../assets/images/home/img_seguro_minutos.png" class="image-carousel" alt="...">
                  </div>
                  <div class="carousel-item">
                    <div class="container-transparent">
                      <p class="text-purple-left">Contrata tu Seguro: De Hogar · Para Auto · De Vida</p>
                      <p class="text-orange-discount">¡Hasta 12 meses sin intereses!</p>
                      <p class="text-orange">Consulta tarjetas participantes</p>
                      <p class="space"></p>
                    </div>
                    <img src="../../../assets/images/home/img_seguro_hogar.png" class="image-carousel" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<div class="container-second">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p class="text-purple-provider">Más de 50 años de experiencia</p>
        <p class="text-grey-provider">y las mejores aseguradoras nos respaldan</p>
      </div>
      <!-- <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_afirme.svg" alt="">
      </div> -->
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_momento.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_aig.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_axa.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_chubb.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_gnp.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_hdi.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_mapfre.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_metlife.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_primero.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_qualitas.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_sura.svg" alt="">
      </div>
      <div class="col-12 col-sm-2 form-group">
        <img class="image-provider" src="../../../assets/images/home/logo_zurich.svg" alt="">
      </div>
    </div>
    <div class="row justify-content-center">    
      <div class="col-12 col-sm-6 col-md-4 form-group">
        <button class="button-purple-provider" (click)="goToPage('nosotros')">Conoce más de GPI</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-modal">
          <p class="title-modal">Solicita tu cotización</p>
          <div>
            <p class="text-contact">Nombre <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.nombre">
          </div>
          <div>
            <p class="text-contact">Correo electrónico <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.email">
          </div>
          <div>
            <p class="text-contact">Número de contacto <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.contacto">
          </div>
          <div>
            <p class="text-contact">Asunto <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.asunto">
          </div>
          <div>
            <p class="text-contact">Mensaje <span class="red">*</span></p>
            <textarea class="form-control" rows="4" [(ngModel)]="contact.mensaje"></textarea>
          </div>
          <div>
            <button class="button-contact" (click)="sendContact()">Enviar</button>
          </div>
          <!-- <p class="text-modal">Llama o escríbenos al <span class="text-modal-bold"> cel. 442 191 0757</span> <br> o envía un correo a <span class="text-modal-bold">contacto@grupogpi.mx</span></p> -->
          <p class="text-modal">Llama a <span class="text-modal-bold"> tel. (442) 628 2596</span></p> <p class="text-modal">o mándanos WhatsApp al <span class="text-modal-bold">(442) 239 2830</span></p>
          <p> También puedes enviar un correo a <span class="text-modal-bold">contacto@grupogpi.mx</span></p>
          <p class="text-modal-paddind">Nuestros asesores con gusto te atenderán</p>
          <button class="button-modal" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertError">
  <div class="container-modal-message">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>Por favor vuelve a intentarlo, <br> es necesario llenar todos los campos</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertEmail">
  <div class="container-modal-message">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>El correo electrónico tiene un formato inválido</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertSuccess">
  <div class="container-modal-message">
    <p class="title-modal-purple">Gracias, tu mensaje se ha enviado</p>
    <p>Pronto, un asesor estará en contacto contigo</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>