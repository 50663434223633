import { Component, OnInit } from '@angular/core';
import { PromiseComponent } from '../promise/promise.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  showQro: boolean;
  showCdmx: boolean;
  showChi: boolean;
  showGdl: boolean;

  weightQro;
  weightCdmx;
  weightChi;
  weightGdl;

  contact = {
    nombre: '',
    email: '',
    contacto: '',
    asunto: '',
    mensaje: ''
  }

  showAlertError;
  showAlertEmail;
  showAlertSuccess;

  constructor(
    public _api: PromiseComponent
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showQro = true;
    this.showCdmx = false;
    this.showChi = false;
    this.showGdl = false;

    this.weightQro = 700;
    this.weightCdmx = 500;
    this.weightChi = 500;
    this.weightGdl = 500;

    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  goToMap(value){
    if(value == 0){
      this.showQro = true;
      this.showCdmx = false;
      this.showChi = false;
      this.showGdl = false;
      this.weightQro = 700;
      this.weightCdmx = 500;
      this.weightChi = 500;
      this.weightGdl = 500;
    }else if(value == 1){
      this.showQro = false;
      this.showCdmx = true;
      this.showChi = false;
      this.showGdl = false;
      this.weightQro = 500;
      this.weightCdmx = 700;
      this.weightChi = 500;
      this.weightGdl = 500;
    }else if(value == 2){
      this.showQro = false;
      this.showCdmx = false;
      this.showChi = true;
      this.showGdl = false;
      this.weightQro = 500;
      this.weightCdmx = 500;
      this.weightChi = 700;
      this.weightGdl = 500;
    }else if(value == 3){
      this.showQro = false;
      this.showCdmx = false;
      this.showChi = false;
      this.showGdl = true;
      this.weightQro = 500;
      this.weightCdmx = 500;
      this.weightChi = 500;
      this.weightGdl = 700;
    }
  }

  validateEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  sendContact(){
    if(this.contact.nombre && this.contact.email && this.contact.contacto && this.contact.asunto && this.contact.mensaje){
      if(!this.validateEmail(this.contact['email'])){
        this.showAlertEmail = true;
        return;
      }
      this._api.send_contact(this.contact).then(response=>{
        if(response['status'] == 'sent'){
          this.showAlertSuccess = true;
        }
        else if(response['status'] == 500){
          this.showAlertError = true;
        }
      }).catch(error=>{
        console.log(error);
      })
    }
    else{
      this.showAlertError = true;
    }
  };

  cancel(){
    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

}
