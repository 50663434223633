<div class="container-main">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-2 form-group">
        <p class="title-footer">Menú</p>
        <p class="cursor" (click)="goToPage('inicio')">Inicio</p>
        <p class="cursor" (click)="goToPage('polizas')">Seguros</p>
        <p class="cursor" (click)="goToPage('fianzas')">Fianzas</p>
        <p class="cursor" (click)="goToPage('capital')">Créditos</p>
        <!-- <p class="cursor" (click)="goToPage('blog')">Blog</p> -->
        <p class="cursor" (click)="goToPage('contacto')">Contacto</p>
      </div>
      <div class="col-12 col-sm-6 form-group">
        <p class="title-footer">Información de interés</p>
        <p class="cursor" (click)="goToPage('nosotros')">Acerca de GPI</p>
        <p><a class="privacity" href="../../../assets/Aviso_de_Privacidad_GPI_2024.pdf" target="_blank">Aviso de Privacidad</a></p>
        <!-- <p>Términos y condiciones</p> -->
      </div>
      <div class="col-12 col-sm-4 form-group">
        <div class="container-image">
          <span><img class="image-footer" src="../../../assets/images/footer/logo_gpi_footer.svg" alt=""></span>
          <p>Siguenos en nuestras redes sociales</p>
          <span>
            <a href="https://www.facebook.com/GPI.SegurosMX" target="_blank"><img class="icon-social" src="../../../assets/images/footer/ico_facebook.svg" alt=""></a>
            <a href="https://www.instagram.com/gpi_seguros/" target="_blank"><img class="icon-social" src="../../../assets/images/footer/ico_instagram-01.png" alt=""></a>
            <a href="https://www.linkedin.com/company/gpi-mexico/" target="_blank"><img class="icon-social" src="../../../assets/images/footer/ico_linkedin.svg" alt=""></a>
          </span>
          <p class="text-mbox">Derechos reservados GPI @2021</p>
        </div>
      </div>
    </div>
  </div>
</div>