<div class="container-main">
  <div class="container">
    <div class="row justify-content-center container-white">
      <div class="col-12 col-sm-6">
        <div class="box-white">
          <p class="text-purple">¡FELICIDADES!</p>
          <p class="subtitle-car">Tu poliza de</p>
          <div class="row">
            <img *ngIf="data.selected_quote.aseguradora == 'ana'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Ana.svg" alt="">
            <img *ngIf="data.selected_quote.aseguradora == 'chubb'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Chubb.svg" alt="">
            <img *ngIf="data.selected_quote.aseguradora == 'gnp'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_GNP.svg" alt="">
            <img *ngIf="data.selected_quote.aseguradora == 'hdi'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_HDI.svg" alt="">
            <img *ngIf="data.selected_quote.aseguradora == 'ps'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Primero.svg" alt="">
            <img *ngIf="data.selected_quote.aseguradora == 'qualitas'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Qualitas.svg" alt="">  
          </div>
          <p class="text">fue emitida correctamente</p>
        </div>
        <div class="box-black">
          <p class="subtitle">NÚMERO DE PÓLIZA</p>
          <p class="title-car">{{policy}}</p>
          <!-- <p class="text-blue">{{data.selected_quote.raw_response.inivig}} al {{data.issue.finvig | date: 'dd/MM/yyyy'}}</p> -->
          <div class="row">
            <div class="col-12 col-sm-6">
              <button *ngIf="provider == 'ana'" class="button-orange" (click)="downloadPdfAna(2)">DESCARGAR</button>
              <button *ngIf="provider == 'chubb'" class="button-orange" (click)="downloadPdfChubb()">DESCARGAR</button>
              <button *ngIf="provider == 'gnp'" class="button-orange" (click)="downloadPdfGnp()">DESCARGAR</button>
              <button *ngIf="provider == 'hdi'" class="button-orange" (click)="downloadPdfHdi()">DESCARGAR</button>
              <button *ngIf="provider == 'ps'" class="button-orange" (click)="downloadPdfPs(data.issue.poliza_pdf)">DESCARGAR</button>
            </div>
            <div class="col-12 col-sm-6">
              <button class="button-white" (click)="redirect('cotizador-auto')">COTIZAR OTRO AUTO</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <div *ngIf="loader" class="container-load">
    <img class="image-load" src="../../../assets/images/loading_2.gif" alt="">
  </div>