// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  IP_CONF_MC: 'https://apigatewaymc.multicotizador.com/',
  SOCKET_ENDPOINT_NOTIFICACIONES: 'wss://apigatewaymc.multicotizador.com/ws/notificaciones/',
  API_MAIL: 'https://mail.mbxservicios.com/mails/',
  URL_QUALITAS: 'https://qualitas-api.multicotizador.com/',
  URL_CHUBB: 'https://avantthya-api.multicotizador.com/',
  URL_ANA: 'https://anaseguros-api.multicotizador.com/',
  URL_HDI: 'https://hdi-api.multicotizador.com/',
  URL_GNP: 'https://gnp-api.multicotizador.com/',
  URL_PS: 'https://ps-api.multicotizador.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
