import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings = {
    'token':'',
    'username':'',
    'full_name':'',
    'first_name':'',
    'last_name':'',
    'email':'',
    'org':'',
    'cotizacion': {},
    'cotizacion_selecionada': {},
    'servicios': {},
    'urlname':'gpi'
  }
  ajustes = {
    'token':'',
    'username':'',
    'full_name':'',
    'first_name':'',
    'last_name':'',
    'email':'',
    'org':'',
    'cotizacion': {},
    'cotizacion_selecionada': {},
    'servicios': {},
    'urlname':'gpi'
  }
  constructor() {
    this.loadSettings();
  }

  loadSettings(){
    if(localStorage.getItem('settings')){
      this.settings = JSON.parse(localStorage.getItem('settings'));
    }
  };

  saveSettings(){
    localStorage.setItem('settings', JSON.stringify(this.settings));
  };

  deleteSettings(){
    this.settings.token = null;    
    this.settings.username = null;
    this.settings.full_name = null;
    this.settings.first_name = null;
    this.settings.last_name = null;
    this.settings.email = null;
    this.settings.urlname = null;
    this.settings.org = null;
    this.settings.cotizacion = null;
    this.settings.cotizacion_selecionada = null;
    this.settings.servicios = null;
    this.saveSettings();
  }
}