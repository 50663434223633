<!-- <div class="container-main">
  <div class="container">
    <div class="container-white">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-purple">Asegura tu auto y gana tranquilidad</p>
          <p class="text-small">¡Hazlo sin miedo!</p>
        </div>
        <div class="col-12 form-group">
          <p class="title-car">SEGURO DE AUTO</p>
          <p class="subtitle-car">Completa tus datos y cotiza en minutos</p>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Correo electrónico</p>
          <input class="form-control" type="email" [(ngModel)]="cotizacion.email">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Sexo</p>
          <div class="btn-group"> 
            <button class="button-sex-m" [ngStyle]="{'background-color':bgSexM}" (click)="selectedSex(1)">Masculino</button>
            <button class="button-sex-f" [ngStyle]="{'background-color':bgSexF}" (click)="selectedSex(2)">Femenino</button>
          </div>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Fecha de Nacimiento</p>
          <input class="form-control" type="date" [(ngModel)]="birthdate" (ngModelChange)="getBirthdate()">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">C.P.</p>
          <input class="form-control" type="text" [(ngModel)]="cotizacion.zip_code" (ngModelChange)="getSuburbs(0)">
        </div>
        <div class="col-12 form-group">
          <hr>
        </div>
        <div class="col-12 form-group">
          <p><span class="subtitle-car">Datos de tu auto</span> (te recomendamos tener tu factura a la mano, para revisar estos datos)</p>
        </div>
        <div class="col-12 form-group">
          <label class="text-form">Tipo de Automóvil</label>
          <select class="form-control" [(ngModel)]="cotizacion.car_class" (change)="getBrands(0)">
            <option *ngFor="let item of types" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <label c>Marca</label>
          <select class="form-control" [(ngModel)]="cotizacion.car_brand" (change)="getYears(0)" [disabled]="!cotizacion.car_class || cotizacion.car_class == 'Selecciona un tipo' || cotizacion.car_class == ''">
            <option *ngFor="let item of brands" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <label class="text-form">Año</label>
          <select class="form-control" [(ngModel)]="cotizacion.car_year" (change)="getModels(0)" [disabled]="!cotizacion.car_brand || cotizacion.car_brand == 'Selecciona una marca' || cotizacion.car_brand == ''">
            <option *ngFor="let item of years" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <label class="text-form">Modelo</label>
          <select class="form-control" [(ngModel)]="cotizacion.model_name" (change)="informationModels()" [disabled]="!cotizacion.car_year || cotizacion.car_year == 'Selecciona un año' || cotizacion.car_year == ''">
            <option *ngFor="let item of models" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-white" (click)="goToPage('inicio')">Regresar</button>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-orange" (click)="getServices()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertError">
  <div class="container-modal">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>{{ messageError }}</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div> -->

<div class="container-main">
  <div class="container">
    <div class="container-white">
      <div class="row">
        <div class="col-12">
          <p class="text-purple">Asegura tu auto y gana tranquilidad</p>
          <p class="text-small">¡Hazlo sin miedo!</p>
        </div>
        <div class="col-12">
          <p class="title-car">SEGURO DE AUTO</p>
          <p class="subtitle-car">Completa tus datos y cotiza en minutos</p>
        </div>

        <div class="col-12 form-group">
          <form [formGroup]="formQuoter" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12 form-group">
                <p class="subtitle">Seleciona tu <span class="text-red">auto</span></p>
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Marca:</label>
                <select class="form-control" formControlName="marca" (change)="getSubbrands()">
                  <option *ngFor="let item of brands" [value]="item.descripcion">
                    {{item.descripcion}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6 ">
                <label class="text-form">Modelo:</label>
                <select class="form-control" formControlName="submarca" (change)="getModels()">
                  <option *ngFor="let item of subbrands" [value]="item.descripcion">
                    {{item.descripcion}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Año:</label>
                <select class="form-control" formControlName="modelo" (change)="getVersions()">
                  <option *ngFor="let item of models" [value]="item.descripcion">
                    {{item.descripcion}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Versión:</label>
                <select class="form-control" formControlName="descripcion">
                  <option *ngFor="let item of versions" [value]="item.descripcion">
                    {{item.descripcion}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Fecha de nacimiento:</label>
                <input class="form-control" type="date" formControlName="fecha_nacimiento" placeholder="dd/mm/aaaa" >
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Sexo:</label>
                <select class="form-control" formControlName="sexo">
                  <option *ngFor="let item of sexs" [value]="item.descripcion">
                    {{item.name}}
                  </option>
                </select>
              </div>
            
              <div class="col-12 col-sm-6 d-flex">
                <div *ngIf="!checkboxZip">
                  <div class="col-12">
                    <label class="text-form">Código postal</label>
                    <input class="form-control" type="text" placeholder="Código Postal" formControlName="cp" (ngModelChange)="getNeighborhoodByZip()" >
                  </div>
                </div>
                <div class="col-12 col-sm-6 m-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" formControlName="check" (change)="checkZip()" checked >
                    <label class="form-check-label" for="flexSwitchCheckChecked">No recuerdo el código postal</label>
                  </div>
                </div>
              </div>
  
              <div *ngIf="checkboxZip" class="d-flex">
                <div class="col-12 col-sm-6" style="margin-right: 30px;">
                  <label class="text-form">Estado</label>
                  <select class="form-control" formControlName="estado" (change)="getCities()">
                    <option *ngFor="let item of states" [value]="item.id_estado">
                      {{item.estado}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="text-form">Ciudad</label>
                  <select class="form-control" formControlName="ciudad" (change)="getNeighborhood()">
                    <option *ngFor="let item of cities" [value]="item.id_ciudad">
                      {{item.ciudad}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-sm-6">
                <label class="text-form">Colonia</label>
                <select class="form-control" formControlName="colonia" (change)="getZipByNeighborhood()">
                  <option *ngFor="let item of neighborhoods" [value]="item.colonia">
                    {{item.colonia}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-4 ">
                <label class="text-form">Nombre:</label>
                <input class="input-form form-control" type="text" placeholder="Nombre" formControlName="nombre" >
              </div>
              <div class="col-12 col-sm-4">
                <label class="text-form">Apellido paterno:</label>
                <input class="select-form form-control" type="text" placeholder="Apellido paterno" formControlName="apellido_paterno">
              </div>
              <div class="col-12 col-sm-4">
                <label class="text-form">Apellido materno:</label>
                <input class="select-form form-control" type="text" placeholder="Apellido materno" formControlName="apellido_materno">
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Correo electronico:</label>
                <input class="input-form form-control" type="text" placeholder="ejemplo@correo.com" formControlName="correo" >
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Teléfono:</label>
                <input class="input-form form-control" type="text" placeholder="Teléfono" formControlName="telefono" >
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Paquete:</label>
                <select class="select-form form-control" formControlName="paquete">
                  <option *ngFor="let item of paquetes" [value]="item.id">
                    {{item.paquete}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6">
                <label class="text-form">Frecuencia:</label>
                <select class="select-form form-control" formControlName="forma_de_pago">
                  <option *ngFor="let item of frecuencias" [value]="item.id">
                    {{item.frecuencia}}
                  </option>
                </select>
              </div>
              <div class="col-12 mt-3">
                <div class="form-check">
                  <input class="form-check-input " type="checkbox" value="" id="flexCheckDefault" formControlName="terminos">
                  <label class="form-check-label" for="flexCheckDefault">
                    <a href="../../../assets/Terminos_y_condiciones.pdf" target="_blank">Acepto términos y condiciones</a>
                  </label>
                </div>
              </div>
              <!-- <div class="col-12 form-group">
                <button class="button-orange" type="submit">COTIZAR</button>
              </div> -->

              <div class="col-12 col-sm-6 form-group">
                <button class="button-white" (click)="goToPage('inicio')" >Regresar</button>
              </div>
              <div class="col-12 col-sm-6 form-group">
                <button class="button-orange" type="submit">Siguiente</button>
              </div>
  
            </div>
            
          </form>
  
        </div>
    </div>
  </div>
</div>