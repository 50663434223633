import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  color = '#c65b13';
  textHome;
  textPolicy;
  textSurety;
  textCredit;
  textBlog;
  textContact;

  showContact;
  showCredit;

  constructor(
    private router: Router,
  	private location: Location
  ) {
    switch (location.path()) {
      case '/inicio':
        this.textHome = this.color;
        break;
      case '/polizas':
        this.textPolicy = this.color;
        break;
      case '/fianzas':
        this.textSurety = this.color;
        break;
      case '/capital':
        this.textCredit = this.color;
        this.showCredit = false;
        break;
      case '/blog':
        this.textBlog = this.color;
        break;
      case '/contacto':
        this.textContact = this.color;
        this.showContact = false;
        break;
      default:
        break;
    }
    router.events.subscribe(val => {
      this.textHome = '#8a8a8a';
      this.textPolicy = '#8a8a8a';
      this.textSurety = '#8a8a8a';
      this.textCredit = '#8a8a8a';
      this.textBlog = '#8a8a8a';
      this.textContact = '#8a8a8a';
      this.showContact = true;
      this.showCredit = true;

      switch (location.path()) {
        case '/inicio':
          this.textHome = this.color;
          break;
        case '/polizas':
          this.textPolicy = this.color;
          break;
        case '/fianzas':
          this.textSurety = this.color;
          break;
        case '/capital':
          this.textCredit = this.color;
          this.showCredit = false;
          break;
        case '/blog':
          this.textBlog = this.color;
          break;
        case '/contacto':
          this.textContact = this.color;
          this.showContact = false;
          break;
        default:
          break;
      }
    });
  }

  ngOnInit(): void {
  }

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

  goToLink(url: string){
    window.open(url, "_blank");
  }

}
