<div class="container-main">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <div class="container-orange">
          <p class="text-white">Contratar un Seguro es sencillo, rápido y accesible</p>
          <p class="text-white-small">¡Hacemos que suceda!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-contact">
    <div class="container">
      <div class="container-transparent">
        <div class="row">
          <div class="col-12 col-sm-8">
            <div class="col-12 form-group">
              <p class="text-grey">Las alianzas que tenemos con las mejores aseguradoras del país y nuestra experiencia de más de 50 años, con atención en más de 4 estados de la República, nos respaldan.</p>
            </div>
            <div class="col-12 form-group">
              <p class="text-grey">Si tienes alguna duda, buscas un Seguro específico o necesitas nuestro apoyo, contáctanos.</p>
            </div>
            <div class="col-12 form-group">
              <p class="text-grey-blod">Contacta con un asesor:</p>
            </div>
            <div class="row">
              <!-- <div class="col-12 col-sm-4 form-group">
                <button class="button-chat">Chat</button>
              </div> -->
              <div class="col-12 col-sm-4 form-group">
                <button class="button-messenger" (click)="goToLink('http://m.me/grupogpi.mx')">Messenger</button>
              </div>
              <div class="col-12 col-sm-4 form-group">
                <button class="button-whatsapp" (click)="goToLink('https://wa.link/8uhq1h')">WhatsApp</button>
              </div>
            </div>
            <div class="col-12 form-group">
              <p class="text-gray"><span class="text-gray-blod"> Teléfono</span>: (442) 628 2596</p>
            </div>
            <div class="col-12 form-group">
              <!-- <p class="text-gray"><span class="text-gray-blod">Celular y WhatsApp</span>: 442 191 0757</p> -->
              <p class="text-gray"><span class="text-gray-blod">WhatsApp</span>: (442) 239 2830</p>
            </div>
            <div class="col-12 form-group">
              <p class="text-gray"><span class="text-gray-blod">Seguimiento de Siniestros: </span>(442) 219.1020</p>

              <p class="text-gray"><span class="text-gray-blod">Recuerda: para reportar un siniestro es necesario que contactes directamente al call center de tu aseguradora.</span></p>
            </div>
            <div class="col-12 form-group">
              <p class="text-gray"><span class="text-gray-blod">Correo electrónico</span>: contacto@grupogpi.mx</p>
            </div>
            <div class="col-12 form-group">
              <div class="border-contact"></div>
            </div>
          </div>
          <div class="col-12 col-sm-4 form-group">
            <div class="container-white">
              <div>
                <p class="title-contact">Envíanos un mensaje, <br>con gusto te atenderemos</p>
              </div>
              <div>
                <p class="text-contact">Nombre <span class="red">*</span></p>
                <input class="form-control" type="text" [(ngModel)]="contact.nombre">
              </div>
              <div>
                <p class="text-contact">Correo electrónico <span class="red">*</span></p>
                <input class="form-control" type="text" [(ngModel)]="contact.email">
              </div>
              <div>
                <p class="text-contact">Número de contacto <span class="red">*</span></p>
                <input class="form-control" type="text" [(ngModel)]="contact.contacto">
              </div>
              <div>
                <p class="text-contact">Asunto <span class="red">*</span></p>
                <input class="form-control" type="text" [(ngModel)]="contact.asunto">
              </div>
              <div>
                <p class="text-contact">Mensaje <span class="red">*</span></p>
                <textarea class="form-control" rows="4" [(ngModel)]="contact.mensaje"></textarea>
              </div>
              <div>
                <button class="button-orange" (click)="sendContact()">Enviar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 form-group">
            <p class="text-orange">Nuestras oficinas</p>
            <p class="text-gray-city" [ngStyle]="{'font-weight':weightQro}" (click)="goToMap(0)">Querétaro</p>
            <p class="text-gray-city" [ngStyle]="{'font-weight':weightCdmx}" (click)="goToMap(1)">CDMX</p>
          </div>
          <div class="col-12 col-sm-6 form-group">
            <p class="text-purple">Sucursales</p>
            <p class="text-gray-city" [ngStyle]="{'font-weight':weightChi}" (click)="goToMap(2)">Chihuahua</p>
            <p class="text-gray-city" [ngStyle]="{'font-weight':weightGdl}" (click)="goToMap(3)">Guadalajara</p>
          </div>
          <div class="col-12 form-group" *ngIf="showQro">
            <p class="text-gray-address">Corporativo Querétaro</p>
            <p class="text-gray-city">Torre Cápita - Av Antea No. 1032, Nivel 10, Oficina 5, Col. Ejido Jurica,
              <br>
              Querétaro, Qro., C.P. 76100
              <br><br>
              <!-- Cel. (442) 186.4095</p> -->
              ·  Tel. (442) 239 2830   ·  WhatsApp: (442) 239 2830<br>
          </div>
          <div class="col-12 form-group" *ngIf="showCdmx">
            <p class="text-gray-address">Oficina CDMX</p>
            <p class="text-gray-city">Bosques Ciruelos No. 140-801, Bosque de las Lomas,
              <br>
              Miguel Hidalgo, CDMX, C.P. 11700
              <br>
              Tel. (55) 5801.9448</p>
          </div>
          <div class="col-12 form-group" *ngIf="showChi">
            <p class="text-gray-address">Oficina Chihuahua</p>
            <p class="text-gray-city">Av. Pedro Zuloaga No. 10800 Int. 102 y 103 Col. Labor de Terrazas,
              <br>
              Chihuahua, Chih., C.P. 31220
              <br>
              Tel. (614) 688.2286</p>
          </div>
          <div class="col-12 form-group" *ngIf="showGdl">
            <p class="text-gray-address">Oficina Guadalajara</p>
            <p class="text-gray-city">The Landmark - Paseo de Los Virreyes No.45, Nivel 9, Puerta de Hierro,
              <br>
              Zapopan, Jal., C.P. 45116
              <br>
              Teléfono: (33) 1129.0987</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-maps" *ngIf="showQro">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d933.0018888538409!2d-100.44907177076574!3d20.709917799135788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d357e3366d9259%3A0x2b96ccba406a038a!2sGPI%20AGENTE%20DE%20SEGUROS%20Y%20DE%20FIANZAS%20S.A.%20DE%20C.V.!5e0!3m2!1ses!2smx!4v1635803088906!5m2!1ses!2smx" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
  <div class="container-maps" *ngIf="showCdmx">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.1996054440815!2d-99.24259458509374!3d19.40377978690067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2010cba2ba58f%3A0x4a8f6a8d57fa403b!2sGaxiola%20Robina%20S.C.!5e0!3m2!1ses!2smx!4v1636136185165!5m2!1ses!2smx" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
  <div class="container-maps" *ngIf="showChi">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7005.492320170568!2d-106.121361!3d28.607391!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ea5d233bce1567%3A0x65d3fe9947e42536!2sAv.%20Pedro%20Zuloaga%2010800%2C%20Chihuahua%2C%20Chih.!5e0!3m2!1ses-419!2smx!4v1636066610571!5m2!1ses-419!2smx" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
  <div class="container-maps" *ngIf="showGdl">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.0677448411907!2d-103.41547348507277!3d20.707473486173264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af027ed0355f%3A0x906b263017808601!2sP.%C2%BA%20de%20Los%20Virreyes%2045-Piso%209%2C%20Puerta%20de%20Hierro%2C%2045116%20Zapopan%2C%20Jal.!5e0!3m2!1ses!2smx!4v1643394710959!5m2!1ses!2smx" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertError">
  <div class="container-modal">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>Por favor vuelve a intentarlo, <br> es necesario llenar todos los campos</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertEmail">
  <div class="container-modal">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>El correo electrónico tiene un formato inválido</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertSuccess">
  <div class="container-modal">
    <p class="title-modal-purple">Gracias, tu mensaje se ha enviado</p>
    <p>Pronto, un asesor estará en contacto contigo</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
