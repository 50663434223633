import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from 'src/app/services/services.index';
import { environment } from 'src/environments/environment';

@Injectable()
export class CarService {


  headers:any = '';
  url:string = '';
  url_mail:string = '';

  constructor(
    public _settings: SettingsService,
    private http: HttpClient
  ) { 
    this.url = environment.IP_CONF_MC,
    this.url_mail = environment.API_MAIL,
    this.headers = { 
      'Content-Type': 'application/json'
    }
  }

  getBrands(){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/marcas/base', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getSubbrands(brand:string){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/submarcas/base/' + brand, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getModels(brand:string, subbrand:string){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/modelos/base/' + brand + '/' + subbrand, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getVersions(brand:string, subbrand:string, model:string){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/descripciones/base/' + brand + '/' + subbrand + '/' + model, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getNeighborhoodbyZip(zip:string){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/colonias-por-cp/base/' + zip, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getNeighborhoodbyZipHdi(url:string,zip:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'colonias-hdi?cp=' + zip + '&empresa=gpi', {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getNeighborhoodbyZipAna(url:string,zip:string){
    return new Promise((resolve,reject) => {
      this.http.post(url+'colonias-por-cp?cp=' + zip, {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getNeighborhoodbyZipGnp(url:string,zip:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'colonias-por-cp?cp=' + zip, {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getNeighborhoodbyZipPrimero(url:string, zip:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'colonias-por-cp?cp=' + zip + '&empresa=AndreaZamora', {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getStates(){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/estados/base', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getCities(state:string){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/ciudades/base/' + state, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getNeighborhood(state:string, city:string){
    return new Promise((resolve,reject) => {
      this.http.get(this.url+'cotizar/colonias/base/' + state + '/' + city, { headers: this.headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getQuote(quote:any){
    return new Promise((resolve,reject) => {
      this.http.post(this.url+'cotizar/cotizar/base', quote, {headers: this.headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

  saveQuote(quote_id:number, payload:any ){
    return new Promise((resolve,reject) => {
      this.http.patch(this.url+'cotizar/cotizaciones-unit/'+quote_id+'/', payload, {headers: this.headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

  sendEmail(data:any){
    return new Promise((resolve,reject) => {
      this.http.post(this.url_mail+'send-email-cotizacion-multicotizador/', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getTypesAna(url:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'categorias', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getModelsAna(url:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'modelos', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getBrandsAna(url:string, type:string, model:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'marcas?modelo='+model+'&categoria='+type, {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getSubbrandsAna(url:string, type:string, model:string, brand:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'submarcas?modelo='+model+'&categoria='+type+'&marca='+brand, {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getVersionsAna(url:string, type:string, model:string, brand:string, subbrand:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'vehiculos?modelo='+model+'&categoria='+type+'&marca='+brand+'&submarca='+subbrand, {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getBrandsChubb(url:string){
    return new Promise((resolve,reject) => {
      this.http.post(url+'marcas', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getSubbrandsChubb(url:string, brand:string){
    let data = {
      marca: brand
    }
    return new Promise((resolve,reject) => {
      this.http.post(url+'submarcas/0', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getModelsChubb(url:string, brand:string, subbrand:string){
    let data = {
      marca: brand,
      submarca: subbrand
    }
    return new Promise((resolve,reject) => {
      this.http.post(url+'modelos', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getTypesChubb(url:string, brand:string, subbrand:string, model:string){
    let data = {
      marca: brand,
      submarca: subbrand,
      modelo: model
    }
    return new Promise((resolve,reject) => {
      this.http.post(url+'tipos/0/0', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getVersionsChubb(url:string, brand:string, subbrand:string, model:string, type:string){
    let data = {
      marca: brand,
      submarca: subbrand,
      modelo: model,
      tipo: type
    }
    return new Promise((resolve,reject) => {
      this.http.post(url+'descr/0', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getBrandsGnp(url:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'marcas', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getSubbrandsGnp(url:string, brand:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'submarcas?marca='+brand, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getModelsGnp(url:string, brand:string, subbrand:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'modelos?marca='+brand+'&submarca='+subbrand, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getVersionsGnp(url:string, brand:string, subbrand:string, model:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'vehiculos?marca='+brand+'&submarca='+subbrand+'&modelo='+model, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getTypesHdi(url:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'tipos', {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getBrandsHdi(url:string,type:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'marcas?tipo='+ type , {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getSubbrandsHdi(url:string,type:string,brand:string){
    return new Promise((resolve,rejects) =>{
      this.http.get(url+'submarcas?tipo=' + type + '&marca=' + brand , {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        rejects(err)
      })
    });
  }

  getModelsHdi(url:string,type:string,brand:string,subbrand:string){
    return new Promise((resolve,rejects) =>{
      this.http.get(url+'modelos?tipo=' + type + '&marca=' + brand + '&submarca=' + subbrand , {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        rejects(err)
      })
    });
  };

  getVersionsHdi(url:string,type:string,brand:string,models:string,subbrand:string){
    return new Promise((resolve,rejects) =>{
      this.http.get(url+'vehiculos?tipo=' + type + '&marca=' + brand + '&modelo=' + models + '&submarca=' + subbrand , {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        rejects(err)
      })
    });
  };
  
  getBrandsPs(url:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'marcas?tipoVehiculo=01', {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getSubbrandsPs(url:string, brand:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'submarcas?marcaVehiculo='+brand, {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getVersionsPs(url:string, brand:string, subbrand:string, year:string){
    return new Promise((resolve,reject) => {
      this.http.get(url+'vehiculos?marcaVehiculo='+brand+'&subMarcaVehiculo='+subbrand+'&anio='+year+'&tipoVehiculo=01', {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  getReQuote(url:string, provider:string, quote:any){
    return new Promise((resolve,reject) => {
      this.http.post(this.url+'cotizar/cotizar-aseguradora/'+provider, quote, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getIssue(url:string, issue:any){
    return new Promise((resolve,reject) => {
      this.http.post(url+'emitir', issue, {headers: this.headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

  sendEmailContact(data:any){
    return new Promise((resolve,reject) => {
      this.http.post(this.url_mail+'send-email-contacto-multicotizador/', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  sendEmailEmision(data:any){
    return new Promise((resolve,reject) => {
      this.http.post(this.url_mail+'send-email-emision-multicotizador/', data, {headers: this.headers})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  downloadPdfAna(url:string, id:number){
    return new Promise((resolve,reject) => {
      this.http.get(url+'imprimir?cotizacion_id=' + id + '&empresa=AndreaZamora', {})
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  downloadPdfHdi(url:string, id : number){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'blob'});
    return this.http.get<Blob>(url + 'imprimir?cotizacion_id=' + id + '&empresa=AndreaZamora', { headers : {}, responseType : 'blob' as 'json'});
  }

}
