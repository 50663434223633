<!-- <div class="container-main">
  <div class="container">
    <div class="container-white">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="row">
            <div class="col-12 form-group">
              <p class="title-car">DATOS DEL AUTO</p>
            </div>
            <div class="col-12 form-group">
              <p class="subtitle-car">Marca</p>
              <p class="text-info-car">{{ cotizacion.car_brand }}</p>
            </div>
            <div class="col-12 form-group">
              <p class="subtitle-car">Año</p>
              <p class="text-info-car">{{ cotizacion.car_year }}</p>
            </div>
            <div class="col-12 form-group">
              <p class="subtitle-car">Modelo</p>
              <p class="text-info-car">{{ cotizacion.model_name }}</p>
            </div>
            <div class="col-12 form-group">
              <button class="button-white" (click)="goToPage('cotizador-auto')">Regresar a inicio</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-8">
          <div class="row">
            <div class="col-12 form-group">
              <p class="title-car">SEGURO DE AUTO</p>
              <p class="subtitle-car">Tipo de seguro</p>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <p class="text-form">Vigencia</p>
              <input class="form-control" type="text" [(ngModel)]="comparador.vigencia" disabled>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <p class="text-form">Frecuencia pago</p>
              <select class="form-control" [(ngModel)]="comparador.frecuencia" (change)="getFrecuency()">
                <option *ngFor="let item of frecuencias" [value]="item.name">
                  {{item.name}}
                </option>
              </select>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <p class="text-form">Paquetes</p>
              <select class="form-control" [(ngModel)]="comparador.paquete" (change)="reQuote()">
                <option *ngFor="let item of paquetes" [value]="item.name">
                  {{item.name}}
                </option>
              </select>
            </div> -->
            <!-- <div class="col-12 col-sm-6 form-group">
              <p class="text-form">Usos</p>
              <input class="form-control" type="text">
            </div> -->
            <!-- <div class="col-12 form-group">
              <button class="button-green" (click)="downloadPdf()">Descarga cotizaciones</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <p class="text-purple">Tenemos {{ comparativa.length }} diferentes cotizaciones para ti, con las mejores aseguradoras.</p>
      </div>
      <div class="col-12 form-group" *ngIf="comparativa.length == 0">
        <img class="image-load" src="../../../assets/images/quotation/loader.gif" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4 form-group" *ngFor="let tarjeta of comparativa">
        <div class="card">
          <div class="card-head">
            <div class="row">
              <div class="col-4 container-provider">
                <img class="image-provider" src="{{ getLogo(tarjeta.service.id) }}" alt="">
              </div>
              <div class="col-8 container-price">
                <p class="text-price-small">Asegura tu auto por solo:</p>
                <p class="text-price" *ngIf="comparador.frecuencia == 'Contado'">{{ tarjeta.quote_pay_frecuency[3].total_amount | currency }}</p>
                <p class="text-price" *ngIf="comparador.frecuencia == 'Semestral'">{{ tarjeta.quote_pay_frecuency[2].total_amount | currency }}</p>
                <p class="text-price" *ngIf="comparador.frecuencia == 'Trimestral'">{{ tarjeta.quote_pay_frecuency[1].total_amount | currency }}</p>
                <p class="text-price" *ngIf="comparador.frecuencia == 'Mensual'">{{ tarjeta.quote_pay_frecuency[0].total_amount | currency }}</p>
              </div>
            </div>
          </div>
          <div class="cord-body">
            <p class="title-provider" *ngIf="tarjeta.service.id == 2">Chubb</p>
            <p class="title-provider" *ngIf="tarjeta.service.id != 2">{{ tarjeta.service.name }}</p>
            <div class="container-description">
              <p class="text-description">{{ tarjeta.car_descr }}</p>
            </div>
            <div *ngIf="tarjeta.coberturas">
              <p class="title-provider">{{ comparador.paquete }}</p>
              <table>
                <thead>
                  <tr>
                    <th>COBERTURA</th>
                    <th>SUMA ASEGURADA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-row" *ngFor="let item of tarjeta.quote_coverages">
                    <td>{{ item.coverage_name }}</td>
                    <td>{{ item.sum_coverage }} <span class="text-coverage-purple" *ngIf="item.deductible_coverage !== '0'">Deducible: {{ item.deductible_coverage }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="button-white" *ngIf="!tarjeta.coberturas" (click)="showCoverages(tarjeta)">Ver coberturas</button>
            <button class="button-white" *ngIf="tarjeta.coberturas" (click)="hideCoverages(tarjeta)">Cerrar coberturas</button>
            <button class="button-orange" (click)="getIssue(tarjeta)">Contratar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- nuevo -->

<div class="container-main">
  <div class="container">
    <div class="row container-white">
      <div class="col-12">
        <p class="title">Selecciona tu seguro de<span class="subtitle"> auto</span></p>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label class="subtitle-car">Marca:</label>
            <p class="text-info-car">{{data.marca}}</p>
          </div>
          <div class="col-12 col-sm-8">
            <label class="subtitle-car">Modelo:</label>
            <p class="text-info-car">{{data.submarca}}</p>
          </div>
          <div class="col-12 col-sm-4">
            <label class="subtitle-car">Año:</label>
            <p class="text-info-car">{{data.modelo}}</p>
          </div>
          <div class="col-12 col-sm-8">
            <label class="subtitle-car">Versión:</label>
            <p class="text-info-car">{{data.descripcion}}</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <form [formGroup]="formQuoter" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12">
              <label>Paquete:</label>
              <select class="form-control" formControlName="paquete" (change)="onSubmit()">
                <option *ngFor="let item of paquetes" [value]="item.id">
                  {{item.paquete}}
                </option>
              </select>
            </div>
            <div class="col-12">
              <label>Frecuencia de pago:</label>
              <select class="form-control" formControlName="forma_de_pago" (change)="onSubmit()">
                <option *ngFor="let item of frecuencias" [value]="item.id">
                  {{item.frecuencia}}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 col-sm-3">
        <button class="button-white" (click)="goBack()">Regresar</button>
        <button *ngIf="quotes.length > 0" class="button-orange" (click)="downloadPdf()">DESCARGAR PDF</button>
        <div class="text-alerts">
          <p class="text-small">*Precio sujeto a cambio sin previo aviso.</p>
          <p class="text-small">*Esta cotización es solo  es para uso particular</p>
          <p class="text-small">*Si requiere un seguro para uso comercial contáctanos dando click <a href="https://wa.link/bdx846" target="_blank">AQUÍ</a></p>
        </div>
      </div>

      <div>
        <div style="margin: 100px; text-align: center;" *ngIf="quotes.length == 0">
          <p style="font-size: 20px; font-weight: 300; color: grey;">
            Si no se encontró una respuesta, puedes comunicarte con nosotros haciendo clic <a href="https://wa.link/bdx846" style="font-weight: 400;" target="_blank">AQUÍ</a> para obtener más información y asistencia personalizada. Apreciamos tu interés y esperamos poder ayudarte.
          </p>
        </div>
        <div *ngIf="quotes.length > 0 && total != 6" class="col-12">
          <img class="gif2" src="../../../assets/buscando.gif" alt="">
        </div>
        <div class="row" *ngFor="let item of quotes">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12 col-sm-4">
            <img *ngIf="item.aseguradora == 'ana'" class="provider-coverage" src="../../../assets/insurers/Logos_aseguradoras_Ana.svg" alt="">
            <img *ngIf="item.aseguradora == 'chubb'" class="provider-coverage" src="../../../assets/insurers/Logos_aseguradoras_Chubb.svg" alt="">
            <img *ngIf="item.aseguradora == 'gnp'" class="provider-coverage" src="../../../assets/insurers/Logos_aseguradoras_GNP.svg" alt="">
            <img *ngIf="item.aseguradora == 'hdi'" class="provider-coverage" src="../../../assets/insurers/Logos_aseguradoras_HDI.svg" alt="">
            <img *ngIf="item.aseguradora == 'ps'" class="provider-coverage" src="../../../assets/insurers/Logos_aseguradoras_Primero.svg" alt="">
            <img *ngIf="item.aseguradora == 'qualitas'" class="provider-coverage" src="../../../assets/insurers/Logos_aseguradoras_Qualitas.svg" alt="">

            <div class="col-12">
              <div class="col-12 d-flex justify-content-center">
              <p class="subtitle-auto">No. de cotización:</p>
              <p class="subtitle-auto-info">{{item.numero_cotizacion}}</p>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <p class="subtitle-auto">Clave del auto: </p>
                <span *ngIf="item.aseguradora == 'ana'" class="subtitle-auto-info">{{item.CVEVEH}}</span>
                <span *ngIf="item.aseguradora == 'chubb'" class="subtitle-auto-info">{{item.CVEVEH}}</span>
                <span *ngIf="item.aseguradora == 'gnp'" class="subtitle-auto-info">{{item.CVEVEH}}</span>
                <span *ngIf="item.aseguradora == 'hdi'" class="subtitle-auto-info">{{item.CVEVEH}}</span>
                <span *ngIf="item.aseguradora == 'ps'" class="subtitle-auto-info">{{item.raw_response.request_json.clave_auto}}</span>
                <span *ngIf="item.aseguradora == 'qualitas'" class="subtitle-auto-info">{{item.raw_response.Movimientos.Movimiento.DatosVehiculo.ClaveAmis}}</span>
            </div>

            </div>
          </div>

          <div class="col-12 col-sm-5">
            <p class="text-purple">Tu vehículo:</p>
            <p *ngIf="item.aseguradora == 'ana'" class="text-auto">{{item.descr_ana}}</p>
            <p *ngIf="item.aseguradora == 'chubb'" class="text-auto">{{item.descr_chubb}}</p>
            <p *ngIf="item.aseguradora == 'gnp'" class="text-auto">{{item.descr_gnp}}</p>
            <p *ngIf="item.aseguradora == 'hdi'" class="text-auto">{{item.descr_hdi}}</p>
            <p *ngIf="item.aseguradora == 'ps'" class="text-auto">{{item.descr_ps}}</p>
            <p *ngIf="item.aseguradora == 'qualitas'" class="text-auto">{{data.descripcion}}</p>
            <p  *ngIf="formQuoter.value.forma_de_pago == 'C'" class="subtitle-car">Pago Único:
              <span *ngIf="item.aseguradora == 'ana'" class="price">{{item.primas.PTOTAL | currency}}</span>
              <span *ngIf="item.aseguradora == 'chubb'" class="price">{{item.primas.PTOTAL | currency}}</span>
              <span *ngIf="item.aseguradora == 'gnp'" class="price">{{item.primas.TOTAL_PAGAR | currency}}</span>
              <span *ngIf="item.aseguradora == 'hdi'" class="price">{{item.primas.PrimaTotal | currency}}</span>
              <span *ngIf="item.aseguradora == 'ps'" class="price">{{item.primas.prima_total | currency}}</span>
              <span *ngIf="item.aseguradora == 'qualitas'" class="price">{{item.primas.PrimaTotal | currency}}</span>
            </p>
            <p  *ngIf="formQuoter.value.forma_de_pago != 'C'" class="subtitle-car">Pago Total:
              <span *ngIf="item.aseguradora == 'ana'" class="price">{{item.primas.PTOTAL | currency}}</span>
              <span *ngIf="item.aseguradora == 'chubb'" class="price">{{item.primas.PTOTAL | currency}}</span>
              <span *ngIf="item.aseguradora == 'gnp'" class="price">{{item.primas.TOTAL_PAGAR | currency}}</span>
              <span *ngIf="item.aseguradora == 'hdi'" class="price">{{item.primas.PrimaTotal | currency}}</span>
              <span *ngIf="item.aseguradora == 'ps'" class="price">{{item.primas.prima_total | currency}}</span>
              <span *ngIf="item.aseguradora == 'qualitas'" class="price">{{item.primas.PrimaTotal | currency}}</span>
            </p>
            <div *ngIf="formQuoter.value.forma_de_pago != 'C'" class="col-12 col-sm-6">
              <p class="subtitle-car">
                Primer pago:
                <span *ngIf="item.aseguradora == 'ana'" class="subprice">{{item.recibos[0].primatotal | currency}}</span>
                <span *ngIf="item.aseguradora == 'chubb'" class="subprice">{{item.recibos.RECIBO[0].PTOTAL | currency}}</span>
                <span *ngIf="item.aseguradora == 'gnp'" class="subprice">{{item.recibos.PRIMER_RECIBO | currency}}</span>
                <span *ngIf="item.aseguradora == 'hdi'" class="subprice">{{item.recibos[0].PrimaTotal | currency}}</span>
                <span *ngIf="item.aseguradora == 'ps'" class="subprice">{{item.recibos.primer_recibo | currency}}</span>
                <span *ngIf="item.aseguradora == 'qualitas'" class="subprice">{{item.recibos[0].PrimaTotal | currency}}</span>
              </p>
              <p>
                <span *ngIf="formQuoter.value.forma_de_pago == 'S'" class="subprice">1 pago subsecuente:</span>
                <span *ngIf="formQuoter.value.forma_de_pago == 'T'" class="subprice">3 pagos subsecuentes:</span>
                <span *ngIf="formQuoter.value.forma_de_pago == 'M'" class="subprice">11 pagos subsecuentes:</span>
                <span *ngIf="item.aseguradora == 'ana'" class="subprice">{{item.recibos[1].primatotal | currency}}</span>
                <span *ngIf="item.aseguradora == 'chubb'" class="subprice">{{item.recibos.RECIBO[1].PTOTAL | currency}}</span>
                <span *ngIf="item.aseguradora == 'gnp'" class="subprice">{{item.recibos.RECIBO_SUBSECUENTE | currency}}</span>
                <span *ngIf="item.aseguradora == 'hdi'" class="subprice">{{item.recibos[1].PrimaTotal | currency}}</span>
                <span *ngIf="item.aseguradora == 'ps'" class="subprice">{{item.recibos.recibo_subsecuente | currency}}</span>
                <span *ngIf="item.aseguradora == 'qualitas'" class="subprice">{{item.recibos[1].PrimaTotal | currency}}</span>
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <button *ngIf="item.aseguradora != 'qualitas'" class="button-white" data-bs-toggle="modal" data-bs-target="#exampleModal2" (click)="reQuoter(item)">Este no es mi auto</button>
            <button class="button-purple" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showCoverages(item)">COBERTURAS</button>
            <button class="button-orange" (click)="issue(item)">CONTINUAR</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Coberturas</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Coberturas</th>
                <th>Suma asegurada</th>
                <th>Deducicble</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of coverages.coberturas">
                <td *ngIf="coverages.aseguradora == 'ana'">{{item.desc}}</td>
                <td *ngIf="coverages.aseguradora == 'chubb' && item.SEL == '1'">{{item.DESC}}</td>
                <td *ngIf="coverages.aseguradora == 'gnp'">{{item.NOMBRE}}</td>
                <td *ngIf="coverages.aseguradora == 'hdi'">{{item.Descripcion}}</td>
                <td *ngIf="coverages.aseguradora == 'ps'">{{item.id}}</td>
                <td *ngIf="coverages.aseguradora == 'qualitas' && item.Cobertura != ''">{{item.Cobertura}}</td>
  
                <td *ngIf="coverages.aseguradora == 'ana'" class="text-table">{{item.sa}}</td>
                <td *ngIf="coverages.aseguradora == 'chubb' && item.SEL == '1'" class="text-table">{{item.SADESC}}</td>
                <td *ngIf="coverages.aseguradora == 'gnp'" class="text-table">{{item.SUMA_ASEGURADA}}</td>
                <td *ngIf="coverages.aseguradora == 'hdi'" class="text-table">{{item.SumaAsegurada | currency}}</td>
                <td *ngIf="coverages.aseguradora == 'ps'" class="text-table">{{item.dsc_sa}}</td>
                <td *ngIf="coverages.aseguradora == 'qualitas' && item.Cobertura != ''" class="text-table">{{item.SumaAsegurada | currency}}</td>
  
                <td *ngIf="coverages.aseguradora == 'ana'" class="text-table">{{item.ded}}</td>
                <td *ngIf="coverages.aseguradora == 'chubb' && item.SEL == '1'" class="text-table">{{item.DEDESC}}</td>
                <td *ngIf="coverages.aseguradora == 'gnp'" class="text-table">{{item.DEDUCIBLE}}</td>
                <td *ngIf="coverages.aseguradora == 'hdi'" class="text-table">{{item.Deducible}}%</td>
                <td *ngIf="coverages.aseguradora == 'ps'" class="text-table">{{item.dsc_ded}}</td>
                <td *ngIf="coverages.aseguradora == 'qualitas' && item.Cobertura != ''" class="text-table">{{item.Deducible | number}}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
          <img *ngIf="provider == 'ana'" class="logo-modal" src="../../../assets/insurers/Logos_aseguradoras_Ana.svg" alt="">
          <img *ngIf="provider == 'chubb'" class="logo-modal" src="../../../assets/insurers/Logos_aseguradoras_Chubb.svg" alt="">
          <img *ngIf="provider == 'gnp'" class="logo-modal" src="../../../assets/insurers/Logos_aseguradoras_GNP.svg" alt="">
          <img *ngIf="provider == 'hdi'" class="logo-modal" src="../../../assets/insurers/Logos_aseguradoras_HDI.svg" alt="">
          <img *ngIf="provider == 'ps'" class="logo-modal" src="../../../assets/insurers/Logos_aseguradoras_Primero.svg" alt="">
          <img *ngIf="provider == 'qualitas'" class="logo-modal" src="../../../assets/insurers/Logos_aseguradoras_Qualitas.svg" alt="">
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="loaderModal">
          <img class="image-load2" src="../../../assets/images/loading_2.gif" alt="">
        </div>
        <form [formGroup]="formQuoter" (ngSubmit)="onSubmitProvider()" *ngIf="reTotal != 1">
          <div class="row" *ngIf="provider == 'ana'">
            <div class="col-12">
              <label>Tipo:</label>
              <select class="form-control" formControlName="tipo" (change)="getBrandsAna()">
                <option *ngFor="let item of categories" [value]="item.id">
                  {{item.text}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Año:</label>
              <select class="form-control" formControlName="modelo" (change)="getBrandsAna()">
                <option *ngFor="let item of models" [value]="item.id">
                  {{item.text}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Marca:</label>
              <select class="form-control" formControlName="marca" name="marca" (change)="getSubbrandsAna()">
                <option *ngFor="let item of brands" [value]="item.id">
                  {{item.text}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Modelo:</label>
              <select class="form-control" formControlName="submarca" (change)="getVersionsAna()">
                <option *ngFor="let item of subbrands" [value]="item.id">
                  {{item.text}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Version:</label>
              <select class="form-control" formControlName="clave_auto" (change)="getClaveAna()">
                <option *ngFor="let item of versions" [value]="item.id">
                  {{item.text}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <button class="button-orange" type="submit">Cotizar</button>
            </div>
          </div>
          <div class="row" *ngIf="provider == 'chubb'">
            <div class="col-12">
              <label>Marca:</label>
              <select class="form-control" formControlName="marca" (change)="getSubbrandsChubb()">
                <option *ngFor="let item of brands" [value]="item.descripcion">
                  {{item.descripcion}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Submarca:</label>
              <select class="form-control" formControlName="submarca" (change)="getModelsChubb()">
                <option *ngFor="let item of subbrands" [value]="item.descripcion">
                  {{item.descripcion}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Año:</label>
              <select class="form-control" formControlName="modelo" (change)="getTypesChubb()">
                <option *ngFor="let item of models" [value]="item.descripcion">
                  {{item.descripcion}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Modelo:</label>
              <select class="form-control" formControlName="tipo" (change)="getVersionsChubb()">
                <option *ngFor="let item of categories" [value]="item.descripcion">
                  {{item.descripcion}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Version:</label>
              <select class="form-control" formControlName="clave_auto" (change)="getClaveChubb()">
                <option *ngFor="let item of versions" [value]="item.cveveh">
                  {{item.descripcion}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
             <button class="button-orange">Cotizar</button>
            </div>
          </div>
          <div class="row" *ngIf="provider == 'gnp'">
            <div class="col-12">
              <label>Marca:</label>
              <select class="form-control" formControlName="marca" (change)="getSubbrandsGnp()">
                <option *ngFor="let item of brands" [value]="item.id">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Submarca:</label>
              <select class="form-control" formControlName="submarca" (change)="getModelsGnp()">
                <option *ngFor="let item of subbrands" [value]="item.id">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Modelo:</label>
              <select class="form-control" formControlName="modelo" (change)="getVersionsGnp()">
                <option *ngFor="let item of models" [value]="item.id">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Version:</label>
              <select class="form-control" formControlName="clave_auto" (change)="getClaveGnp()">
                <option *ngFor="let item of versions" [value]="item.clave">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
             <button class="button-orange">Cotizar</button>
            </div>
          </div>
          <div class="row" *ngIf="provider == 'hdi'">
            <div class="col-12">
              <label>Tipo:</label>
              <select class="form-control" formControlName="tipo" (change)="getBrandsHdi()">
                <option *ngFor="let item of types" [value]="item.id">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Marca:</label>
              <select class="form-control" formControlName="marca" name="marca" (change)="getSubbrandsHdi()">
                <option *ngFor="let item of brands" [value]="item.id">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Modelo:</label>
              <select class="form-control" formControlName="submarca" (change)="getModelsHdi()">
                <option *ngFor="let item of subbrands" [value]="item.id">
                  {{item.descr}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Año:</label>
              <select class="form-control" formControlName="modelo" (change)="getVersionsHdi()">
                <option *ngFor="let item of models" [value]="item">
                  {{item}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>

            <div class="col-12">
              <label>Version:</label>
              <select class="form-control" formControlName="clave_auto" (change)="getClaveHdi()">
                <option *ngFor="let item of versions" [value]="item.idVehiculo">
                  {{item.version_descr}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <button class="button-orange" type="submit">Cotizar</button>
            </div>
          </div>
          <div class="row" *ngIf="provider == 'ps'">
            <div class="col-12">
              <label>Marca:</label>
              <select class="form-control" formControlName="marca" (change)="getSubbrandsPs()">
                <option *ngFor="let item of brands" [value]="item.idMarca">
                  {{item.marca}}
                </option>
              </select>
              <!-- <small *ngIf="brandError" class="small-red">Marca es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Submarca:</label>
              <select class="form-control" formControlName="submarca" (change)="getVersionsPs()">
                <option *ngFor="let item of subbrands" [value]="item.idSubMarca">
                  {{item.subMarca}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
              <label>Version:</label>
              <select class="form-control" formControlName="clave_auto" (change)="getClavePs()">
                <option *ngFor="let item of versions" [value]="item.clave">
                  {{item.version}}
                </option>
              </select>
              <!-- <small *ngIf="subbrandError" class="small-red">Modelo es un campo requerido.</small> -->
            </div>
            <div class="col-12">
             <button class="button-orange" type="submit">Cotizar</button>
            </div>
          </div>
        </form>
        <div *ngIf="reTotal == 1">
          <p class="title-popUp-success">¡Listo!</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="container-load">
  <img class="image-load" src="../../../assets/images/loading_2.gif" alt="">
</div>

  <!-- PDF -->
  <div>
    <div style="position: absolute; top: -4000px; background-color: #ffffff; left: 0; padding: 0px; ">
      <div class="container" id="htmlData">
        <div class="col-12" style="background-color: #ffffff; margin: 0 0 0 0; width: 100%;">
          <img style="display: block; height: 100px; margin: 0 auto;" src="../../../assets/images/logotipo.png" alt="">
        </div>
        <div class="row justify-content-center" style=" padding: 0; background-color: #e2e2e2;">
          <div class="col-sm-5" *ngFor="let item of quotes" style=" background-color: #ffffff; box-shadow: 0px 13px 11px -5px rgba(0, 0, 0, 0.25); border-radius: 10px; margin: 30px 15px 40px 15px; padding: 20px;">
              <table style="font-size: 10px; padding: 0; width: 100%;">
                <tr style="border: 1px solid #666666;">
                  <td style="border-right: 1px solid #666666; color: #041434; font-size: 14px; height: 48px; width: 60%;">
                    <p *ngIf="item.aseguradora == 'ana'" style="margin: 0; padding: 0 0 0 10px;">{{item.DESC}}</p>
                    <p *ngIf="item.aseguradora == 'chubb'" style="margin: 0; padding: 0 0 0 10px;">{{item.DESC}}</p>
                    <p *ngIf="item.aseguradora == 'gnp'" style="margin: 0; padding: 0 0 0 10px;">
                      {{item.descr_gnp}}
                      <!-- {{item.DESC}} -->
                    </p>
                    <p *ngIf="item.aseguradora == 'hdi'" style="margin: 0; padding: 0 0 0 10px;">{{item.DESC}}</p>
                    <p *ngIf="item.aseguradora == 'ps'" style="margin: 0; padding: 0 0 0 10px;">
                      {{item.descr_ps}}
                      <!-- {{item.DESC}} -->
                    </p>
                    <p *ngIf="item.aseguradora == 'qualitas'" style="margin: 0; padding: 0 0 0 10px;">{{data.descripcion}}</p>
                  </td>
                  <td style="border-right: 1px solid #666666; color: #041434; font-size: 20px; font-weight: 700; width: 25%;">
                    <p *ngIf="item.aseguradora == 'ana'" style="margin: 0; padding: 0 0 0 10px;">{{item.primas.PTOTAL | currency}}</p>
                    <p *ngIf="item.aseguradora == 'chubb'" style="margin: 0; padding: 0 0 0 10px;">{{item.primas.PTOTAL | currency}}</p>
                    <p *ngIf="item.aseguradora == 'gnp'" style="margin: 0; padding: 0 0 0 10px;">{{item.primas.TOTAL_PAGAR | currency}}</p>
                    <p *ngIf="item.aseguradora == 'hdi'" style="margin: 0; padding: 0 0 0 10px;">{{item.primas.PrimaTotal | currency}}</p>
                    <p *ngIf="item.aseguradora == 'ps'" style="margin: 0; padding: 0 0 0 10px;">{{item.primas.prima_total | currency}}</p>
                    <p *ngIf="item.aseguradora == 'qualitas'" style="margin: 0; padding: 0 0 0 10px;">{{item.primas.PrimaTotal | currency}}</p>
                  </td>
                  <td style="width: 15%;">
                    <img *ngIf="item.aseguradora == 'ana'" style="display: block; height: 24px; margin: 0 auto;" src="../../../assets/insurers/ana.png" alt="">
                    <img *ngIf="item.aseguradora == 'chubb'" style="display: block; height: 24px; margin: 0 auto;" src="../../../assets/insurers/chubb.png" alt="">
                    <img *ngIf="item.aseguradora == 'gnp'" style="display: block; height: 24px; margin: 0 auto;" src="../../../assets/insurers/gnp.png" alt="">
                    <img *ngIf="item.aseguradora == 'hdi'" style="display: block; height: 24px; margin: 0 auto;" src="../../../assets/insurers/hdi.png" alt="">
                    <img *ngIf="item.aseguradora == 'ps'" style="display: block; height: 24px; margin: 0 auto;" src="../../../assets/insurers/primero.png" alt="">
                    <img *ngIf="item.aseguradora == 'qualitas'" style="display: block; height: 24px; margin: 0 auto;" src="../../../assets/insurers/qualitas.png" alt="">
                  </td>
                </tr>

                <tr *ngIf="formQuoter.value.forma_de_pago != 'C'" style="border: 1px solid #666666; font-size: 14px; font-weight: 500; width: 25%;">
                  <td>
                    <p class="subprice">
                      Primer pago:
                      <span *ngIf="item.aseguradora == 'ana'" class="subprice">{{item.recibos[0].primatotal | currency}}</span>
                      <span *ngIf="item.aseguradora == 'chubb'" class="subprice">{{item.recibos.RECIBO[0].PTOTAL | currency}}</span>
                      <span *ngIf="item.aseguradora == 'gnp'" class="subprice">{{item.recibos.PRIMER_RECIBO | currency}}</span>
                      <span *ngIf="item.aseguradora == 'hdi'" class="subprice">{{item.recibos[0].PrimaTotal | currency}}</span>
                      <span *ngIf="item.aseguradora == 'ps'" class="subprice">{{item.recibos.primer_recibo | currency}}</span>
                      <span *ngIf="item.aseguradora == 'qualitas'" class="subprice">{{item.recibos[0].PrimaTotal | currency}}</span>
                    </p>
                        
                  </td>
                  <td></td>
                </tr>
                <tr *ngIf="formQuoter.value.forma_de_pago != 'C'" style="border: 1px solid #666666; font-size: 14px; font-weight: 500; width: 25%;">
                  <td>
                    <p>
                      <span *ngIf="formQuoter.value.forma_de_pago == 'S'" class="subprice">1 pago subsecuente:</span>
                      <span *ngIf="formQuoter.value.forma_de_pago == 'T'" class="subprice">3 pagos subsecuentes:</span>
                      <span *ngIf="formQuoter.value.forma_de_pago == 'M'" class="subprice">11 pagos subsecuentes:</span>
                      <span *ngIf="item.aseguradora == 'ana'" class="subprice">{{item.recibos[1].primatotal | currency}}</span>
                      <span *ngIf="item.aseguradora == 'chubb'" class="subprice">{{item.recibos.RECIBO[1].PTOTAL | currency}}</span>
                      <span *ngIf="item.aseguradora == 'gnp'" class="subprice">{{item.recibos.RECIBO_SUBSECUENTE | currency}}</span>
                      <span *ngIf="item.aseguradora == 'hdi'" class="subprice">{{item.recibos[1].PrimaTotal | currency}}</span>
                      <span *ngIf="item.aseguradora == 'ps'" class="subprice">{{item.recibos.recibo_subsecuente | currency}}</span>
                      <span *ngIf="item.aseguradora == 'qualitas'" class="subprice">{{item.recibos[1].PrimaTotal | currency}}</span>
                    </p>
                  </td>
                </tr>

                <tr style="background-color: #eeeeee; border-left: 1px solid #666666; border-bottom: 1px solid #666666; border-right: 1px solid #666666; color: #f42c04; font-size: 15px; font-weight: 700; padding: 0 0 0 10px; margin: 0;">
                  <td style="border-right: 1px solid #666666; font-weight: 700; padding: 0 0 0 4px;">Cobertura</td>
                  <td style="border-right: 1px solid #666666; font-weight: 700; padding: 0 0 0 4px;">Suma asegurada</td>
                  <td style="font-weight: 700; padding: 0 0 0 4px;">Deducible</td>
                </tr>
                <tr style="background-color: #eeeeee; border-left: 1px solid #666666; border-bottom: 1px solid #666666; border-right: 1px solid #666666; padding: 0;" *ngFor="let cov of item.coberturas">
                  <td *ngIf="item.aseguradora == 'ana'" style="border-right: 1px solid #666666; padding: 0 0 0 4px;">{{cov.desc}}</td>
                  <td *ngIf="item.aseguradora == 'chubb' && cov.SEL == '1'" style="border-right: 1px solid #666666; padding: 0 0 0 4px;">{{cov.DESC}}</td>
                  <td *ngIf="item.aseguradora == 'gnp'" style="border-right: 1px solid #666666; padding: 0 0 0 4px;">{{cov.NOMBRE}}</td>
                  <td *ngIf="item.aseguradora == 'hdi'" style="border-right: 1px solid #666666; padding: 0 0 0 4px;">{{cov.Descripcion}}</td>
                  <td *ngIf="item.aseguradora == 'ps'" style="border-right: 1px solid #666666; padding: 0 0 0 4px;">{{cov.id}}</td>
                  <td *ngIf="item.aseguradora == 'qualitas' && cov.Cobertura != ''" style="border-right: 1px solid #666666; padding: 0 0 0 4px;">{{cov.Cobertura}}</td>
      
                  <td *ngIf="item.aseguradora == 'ana'" style="border-right: 1px solid #666666; padding: 0 4px 0 0; text-align: right;">{{cov.sa}}</td>
                  <td *ngIf="item.aseguradora == 'chubb' && cov.SEL == '1'" style="border-right: 1px solid #666666; padding: 0 4px 0 0; text-align: right;">{{cov.SADESC}}</td>
                  <td *ngIf="item.aseguradora == 'gnp'" style="border-right: 1px solid #666666; padding: 0 4px 0 0; text-align: right;">{{cov.SUMA_ASEGURADA}}</td>
                  <td *ngIf="item.aseguradora == 'hdi'" style="border-right: 1px solid #666666; padding: 0 4px 0 0; text-align: right;">{{cov.SumaAsegurada | currency}}</td>
                  <td *ngIf="item.aseguradora == 'ps'" style="border-right: 1px solid #666666; padding: 0 4px 0 0; text-align: right;">{{cov.dsc_sa}}</td>
                  <td *ngIf="item.aseguradora == 'qualitas' && cov.Cobertura != ''" style="border-right: 1px solid #666666; padding: 0px 4px 0px 0; text-align: right;">{{cov.SumaAsegurada | currency}}</td>
      
                  <td *ngIf="item.aseguradora == 'ana'" style="padding: 0 4px 0 0; text-align: right;">{{cov.ded}}</td>
                  <td *ngIf="item.aseguradora == 'chubb' && cov.SEL == '1'" style="padding: 0 4px 0 0; text-align: right;">{{cov.DEDESC}}</td>
                  <td *ngIf="item.aseguradora == 'gnp'" style="padding: 0 4px 0 0; text-align: right;">{{cov.DEDUCIBLE}}</td>
                  <td *ngIf="item.aseguradora == 'hdi'" style="padding: 0 4px 0 0; text-align: right;">{{cov.Deducible}}%</td>
                  <td *ngIf="item.aseguradora == 'ps'" style="padding: 0 4px 0 0; text-align: right;">{{cov.dsc_ded}}</td>
                  <td *ngIf="item.aseguradora == 'qualitas' && cov.Cobertura != ''" style="padding: 0 4px 0 0; text-align: right;">{{cov.Deducible | number}}%</td>
                </tr>
              </table>
          </div>
        </div>
        <div class="row" style="margin: 40px 0 0; background-color: #ffffff;">
          <div class="col-6">
            <img class="image-signature" style="width: 80%; margin: 0 0 0 10px;" src="../../../assets/images/firma1.jpeg" alt="">
          </div>
          <div class="col-6">
            <img  class="image-signature" style="width: 80%;" src="../../../assets/images/firma2.jpeg" alt="">
          </div> 
        </div>
      </div>
    </div>
  </div>
</div>